export const textSwe=[
"Mejla den här frågan till våra folkvalda riksdagsledamöter och höj din röst för skarpare klimatbeslut:",
"Är du villig att vara med om att fatta beslut för klimatet, genom att rösta ja till en motion om att uppdra åt regeringen att genomföra dessa tio punkter?",
"Kan du motivera ditt svar?",
"Välj och klicka på en riksdagsledamot som ej redan har svarat.",
"Kopiera text och rubrikrad på ledamotens sida och mejla det till riksdagsledamoten från din egen e-post.",
"Om tillräckligt många riktar frågan, så kan vi tillsammans få riksdagsledamöterna att svara.",
"Mejlet som ska skickas till ledamoten:",    
"Rubrik:",
"Riksdagen för framtiden + DITT NAMN (detta är för att komma runt eventuella spam-filter.)",
]