import React from "react";
import { Grommet } from "grommet";
import { graphql } from "gatsby";
import { ContributePageComponent } from "../components/contributePage";
import { theme } from "../theme/theme";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";

export const query = graphql`
query ContributePageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }}`

const ContributePage = (props) => {
    const { data, errors } = props;


    if (errors) {
        return (
            <Layout>
                <GraphQLErrorList errors={errors} />
            </Layout>
        );
    }

    const site = (data || {}).site;


    if (!site) {
        throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
        );
    }



    return (
        <Grommet theme={theme}>
            <Layout>
                <SEO
                    title={site.title}
                    description={site.description}
                    keywords={site.keywords}
                />
                <ContributePageComponent/>

            </Layout>
        </Grommet>
    );
};

export default ContributePage;
