export const textEng=[
"Email this question to our elected Members of Parliament and raise your voice for sharper climate decisions:",
"Are you willing to make the necessary decisions for the future, by voting in favor of a motion to commission our government to decide on ten essential action points for a sustainable future?",
"Can you justify your answer?",
"Select and click on a Member of Parliament, who has yet to reply.",
"Copy text and headline found on the Member of Parliament’s page and send it to the Member of Parliament from your personal email address. ",
"If enough people ask the question, together we can get the Members of Parliament to answer and take responsibility for our common future.",  
"Letter to the Member of Parliament:",    
"Subject:",
"Riksdagen för framtiden + YOUR NAME (This to avoid potential email spam filters)",
]