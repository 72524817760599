import React, { useContext } from "react";
import {useSelector} from "react-redux"
import { Box, Text, ResponsiveContext, Anchor } from "grommet";
import { Card } from "../indexPage/contributeComponent/card";
import { TextPageLayout } from "../textPageLayout";
import { MailComponentSwe } from "../personPage/elements/mailComponentSwe";
import { MailComponentEng } from "../personPage/elements/mailComponentEng";
import { textSwe } from "./textSwe";
import { textEng } from "./textEng";
export const ContributePageComponent = () => {
    const language = useSelector(state=> state.language.language);
    const size = useContext(ResponsiveContext)
    let dir = size === "small" || size === "xsmall" ? "column" : "row";
    const textArray = language==="SWE"?textSwe:textEng;
    const headingText = language==="SWE"?"Hur fungerar det?":"How does it work?";
    const width1 = size === "small" || size === "xsmall" ? "25%" : "15%";
    const width2 = size === "small" || size === "xsmall" ? "75%" : "89%";
    const share = language ==="SWE"?"Sprid vidare!": "Tell your friends!"
    const content =
        <Box gap="small" >
            <Box>
                <Text color="darkGreen">
                {textArray[0]}
                </Text>
            </Box>
            <Box gap="small" background="white" round="small">
                <Text color="darkGreen" weight="bold">
                    {textArray[1]}
                </Text>
                <Text color="darkGreen" weight="bold">
                {textArray[2]}
                </Text>
            </Box>
            <Box direction={dir} align="center" justify="start" gap="large">
                <Card
                    text={textArray[3]}
                    number="1"
                />
                <Card
                    text={textArray[4]}
                    number="2"
                />
                <Card
                    text={textArray[5]}
                    number={share}
                />
            </Box>
            <Box margin={{ top: "24px" }} gap="small" width="large" background="indexGreen" pad="medium" align="center">
            <Text color="darkGreen" weight="bold">{textArray[6]}</Text>
            
            <Box direction="row" gap="small">
                <Box width={width1} gap="small">
                <Text color="darkGreen" weight="bold">{textArray[7]}</Text>
                <Box/>
                <Box/>
                {size==="xsmall"&&<Box height="50px"/>}
                {size==="small"&&<Box height="8px"/>}
                <Text color="darkGreen" weight="bold">Text: </Text>
                </Box>
                <Box width={width2} gap="small">
                <Text color="darkGreen">{textArray[8]}</Text>
                {language==="SWE"?<MailComponentSwe/>:<MailComponentEng/>}
                </Box>
                </Box>
                        </Box>
        </Box>
        ;
    return (

        <TextPageLayout header={headingText} content={content} />
    )
}
